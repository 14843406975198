.spinner-brand-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 128px;
  height: 64px;
  transform: translate(-50%, -50%);
  transform-origin: bottom;
  animation: draw 2s infinite;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
}

div.spinner-pulser {
  position: absolute;
  margin: auto auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: #DDD;
  opacity: 0;
}

div.spinner-pulser:nth-child(1){
  animation: pulse 2s infinite;
}
div.spinner-pulser:nth-child(2){
  animation: pulse 2s infinite 0.4s;
}
div.spinner-pulser:nth-child(3){
  animation: pulse 2s infinite 0.8s;
}

@keyframes pulse {
  0% { 
    transform: scale(.1);
    opacity: 0;
  }
  50% { 
    opacity: .7;
  }
  100% { 
    transform: scale(1.6); 
    opacity: 0;
  }
}