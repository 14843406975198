.ck-content h2 {
  font-size: 2.5em;
  line-height: 2;
  text-align: center;
}

.ck-content h4 {
  font-size: 1.6em;
  line-height: 2;
  text-align: center;
}

.ck-content p {
  line-height: 2;
  margin-bottom: 20px;
}

.ck-content hr {
  height: 1px!important;
  margin: 20px 0!important;
}